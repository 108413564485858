.tooltip-link {
  width: 215px !important;
  height: 45px !important;
  background-color: #b84949 !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27) !important;
  color: #444444 !important;
  opacity: 1 !important;
}

.label-acess-link {
  width: 76px;
  height: 14px;
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #444444;
  opacity: 1 !important;
}

.label-btn-imag {
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
}
.label-btn-imag:hover {
  cursor: pointer;
  border-bottom: 1px solid #444444;
}
.extra {
  pointer-events: auto !important;
  width: 215px;
  height: 45px;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
}

.btn-edit-image {
  pointer-events: auto !important;
  width: 228px;
  height: 45px;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
  position: absolute;
  top: 50%;
  left: 40%;
  display: none;
}

.btn-edit-image-show {
  pointer-events: auto !important;
  width: 228px;
  height: 45px;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
  position: absolute;
  top: 50%;
  left: 35%;
  display: inline-block;
  margin-top: 1px;
}
.btn-edit-image-show-carousel {
  pointer-events: auto !important;
  width: 70%;
  height: 7%;
  opacity: 1 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer !important;
  position: absolute;
  top: 43%;
  left: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
}

.container-tooltip-carousel {
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  justify-content: space-evenly;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
}

.extra:hover {
  visibility: visible !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

.label-link {
  width: 76px;
  height: 14px;
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #444444;
  opacity: 1 !important;
}
.extra_carousel {
  pointer-events: auto !important;
  width: 400px;
  height: 45px;
  background-color: #ffffff !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  opacity: 1 !important;
}
.label-btn-text {
  opacity: 0.63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444444;
  text-align: center;
}
.container-tooltip-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27);
  width: 100%;
}

.label-btn-text:hover {
  cursor: pointer;
  border-bottom: 1px solid #444444;
}
