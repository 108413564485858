.body-card-product-list-rigth {
  height: 400px;
  width: 30%;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;

  margin-bottom: 50px;
}
