.teste {
  border-radius: 50px !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  height: 51px;
}

.second-header-body {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnicon:hover {
  opacity: 0.36;
  cursor: pointer;
  color: #403f4c;
}

.pagination-text {
  font-family: 'Times New Roman', Times, serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-left: 20px;
  margin-right: 20px;
}

.pagination-text-number {
  width: 8px;
  height: 14px;
  font-family: Rubik;
  font-size: 14.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-text-number:hover {
  color: #000000;
}

.size-pagination {
  font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.div-pagination {
  height: 1.4px;
  opacity: 0.1;
  border: solid 0.7px #000000;
  margin-bottom: 18px;
}
.card-division {
  width: none;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.arrow-academy:hover {
  fill: #403f4c;
  cursor: pointer;
}

.step-academy:hover {
  opacity: 0.39;
  fill: #403f4c;
  cursor: pointer;
}

.btn-login:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  font-size: 12px;
}

.foto-perfil {
  margin-left: 15px;
  height: 14px;
  margin-top: 10px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.cardbodyitem {
  cursor: pointer;
}

.dialog-text {
  height: 24px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #616161;
}

.dialogyesno-body {
  padding: 20px;
}

.btn-dialog {
  width: 100px;
  height: 40px;
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 40px;
  border-radius: 2.1px;
  background-color: #e5156b;
  border: none;
  color: white;
}
.btn-dialog-alert {
  width: 100px;
  height: 40px;
  margin-left: 30%;
  margin-right: 0px;
  margin-top: 40px;
  border-radius: 2.1px;
  background-color: #e5156b;
  border: none;
  color: white;
}
.btn-dialog:hover {
  cursor: pointer;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}
.btn-dialog-alert:hover {
  cursor: pointer;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.btn-card-curso {
  width: 100%;
  height: 30px;
  margin-top: 2px;
  border-radius: 0px 0px 10px 10px;
  border: none;
  background-color: #ffffff;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  opacity: 0.36;
  border-top: solid 1px #979797;
}

.btn-card-curso:hover {
  background-color: rgba(172, 172, 172, 0.3);
  cursor: pointer;
}

.text-actions {
  height: 12px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.label-link {
  color: '#acacac';
}

.text-area-content {
  border: none;
  width: 59px;
  height: 12px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  width: 100%;
  height: 150px;
  background-color: #ffffff;
}

.text-place {
  width: 109px;
  height: 20px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.btn-click:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  font-size: 12px;
  cursor: pointer;
}
.btn-rascunho {
  width: 147.4px;
  height: 27px;
  border-radius: 2.1px;
  border: solid 1px #403f4c;
  background-color: white;
  margin-left: 10px;
}
.btn-rascunho:hover {
  cursor: pointer;
}
.text-avaible-course {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #1bab60;
}

.text-disable-course {
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b8b8b8;
}

.text-default-disable-course {
  width: 111px;
  height: 14px;
  margin-top: 10px;
  margin-left: 70px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b8b8b8;
}
.svg-access {
  fill: red;
}

.name-file-label {
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 5px;
  color: #403f4c;
}
.btn-add-file {
  width: 216px;
  height: 32px;
  border: none;
  border-radius: 7px;
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.32);
}
.btn-add-file:hover {
  cursor: pointer;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.32);
}
.btn-text-label {
  height: 12px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.length-field-text {
  margin-top: 10px;
  height: 14px;
  opacity: 0.37;
  font-family: Rubik;
  font-size: 14.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}
.label-load {
  width: 304px;
  height: 28px;
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-top: 32%;
}

.label-modulos {
  width: 304px;
  height: 28px;
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 35%;
}
.divisor-modulos {
  background-color: #b8b8b8;
  height: 2px;
  width: 100%;
  opacity: 0.25;
  margin-right: 1%;
}

.label-dialog-tag {
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.divi-dialog-tag {
  background-color: #b8b8b8;
  height: 2px;
  opacity: 0.25;
  margin-right: 7%;
  margin-left: 4%;
}

.title-header {
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  padding: 20px;
}

.CircularProgressbarItem {
  width: 50%;
  margin-top: -20%;
}

.CircularProgressbarItem .CircularProgressbarItem-path {
  stroke: #e5156b;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbarItem .CircularProgressbarItem-trail {
  stroke: #d6d6d6;
}

.CircularProgressbarItem .CircularProgressbarItem-text {
  fill: #e5156b;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbarItem .CircularProgressbarItem-background {
  fill: #d6d6d6;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-background {
  fill: #e5156b;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-text {
  fill: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-path {
  stroke: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-trail {
  stroke: transparent;
}

.CircularProgressbarItemdetermineted {
  margin-left: 15%;
  fill: black;
}

.dropzone-avatar {
  width: 320px;
  margin-left: 20%;
  border-style: none;
  border-radius: 10px;
  height: 147px;
  border-color: #c9c7c7;
}

.dropzone-image {
  width: 100%;
  height: 100%;
  border-color: #c9c7c7;
}

.label-description-canvas {
  height: 13px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-header:hover {
  cursor: pointer;
}
