.place-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-place-holder {
  width: 250px;
  height: 280px;
}

.row-card-corses-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.view-characters {
  font-size: 12px;
}

.request-error-message {
  margin: 0;
  font-size: 36px;
  font-weight: 600;
}

.loader-container {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  transform: translateX(calc(100px)) translateY(50% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.loader-container__heading {
  margin-top: 32px;
  font-size: 36px;
}

.footer-pagination {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
