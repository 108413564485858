.list-card-course-rows {
  display: flex;
  overflow-y: scroll;
  width: 80%;
  margin-left: 1%;
  height: 350px;
  margin-left: 10%;
  overflow-y: hidden;
}

.list-cards-rows {
  display: flex;
  overflow-y: scroll;
  width: 95%;
  margin-left: 1%;
  overflow-y: hidden;
  margin-bottom: 50px;
  position: relative;
}

.blur-div {
  width: 100px;
  margin-left: 86%;
  height: 350px;
  margin-top: -360px;
  z-index: 5000;
  filter: blur(20px);
  background-color: whitesmoke;
}

.blur-div-edit-cards {
  width: 100px;
  margin-left: 90%;
  height: 450px;
  margin-top: -500px;
  z-index: 5000;
  filter: blur(30px);
  background-color: whitesmoke;
}
.row-cards {
  display: flex;
}

.blur-div-init {
  width: 100px;
  height: 350px;
  margin-top: 0px;
  margin-left: 6%;
  position: absolute;
  z-index: 5000;
  filter: blur(20px);
  background-color: white;
}
