.header-painel-controller {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  height: 50px;
}

.header-controller-list {
  display: flex;
  list-style-type: none;
  padding-inline-start: 0;
}

.main-label-painel {
  font-family: Rubik;
  font-size: 45.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.action-label-painel {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #999999;
  margin-left: 20px;
}

.action-label-painel-selected {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #999999;
  margin-left: 20px;
  border-bottom: solid 2px #e5156b;
  cursor: pointer;
}

.action-label-painel:hover {
  border-bottom: solid 2px #e5156b;
  cursor: pointer;
}
