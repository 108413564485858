.title-canvas {
  margin-top: 30px;
  height: 36px;
  font-family: Rubik;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.form-edit-canvas-body {
  display: flex;
  flex-direction: column;
}

.body-canvas-edit {
  margin-bottom: 5%;
}

.button-schedule {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-right: 9%;
}

.container-modal-schedule {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row-exit-schedule {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
}

.label-title {
  margin: 0 auto;
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  color: #3b3b3b;
}

.body-schedule {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-schedule-confirm {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0 20px;
}

.label-form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label-chindren-date {
  text-align: initial;
}

.label-chindren {
  margin-top: 20px;
  text-align: initial;
}

.label-chindren-button {
  margin: 25px 0 20px;
  align-items: center;
}

.label-confirm-button {
  margin: 50px 0 20px;
  align-items: center;
}

.form-choice {
  width: 100%;
  margin: 40px 20px 20px;
}

.body-first-question {
  display: flex;
  flex-direction: column;
}

.form-questions {
  display: flex;
  flex-direction: column;
}

.form-title-preview {
  margin: 0;
  padding: 0;
  width: 67%;
  height: 130px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.body-options-form {
  margin-bottom: 50px;
}

.title-header {
  font-size: 18px;
}

.selected-component-form {
  height: 46px;
  width: 400px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.body-select-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ededee;
}

.body-select-form:hover {
  cursor: pointer;
  background-color: #ededee;
}

.body-components-end-date {
  display: flex;
  flex-direction: column;
}

.form-title-question {
  margin: 0;
  font-size: 18px;
  color: #403f4c;
  font-weight: 500;
  text-align: left;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
  padding: 20px 20px 0px;
}

.components-question-title {
  margin-bottom: 20px;
}

.btn-body {
  display: flex;
  cursor: pointer;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  align-items: center;
  letter-spacing: 0.5px;
  justify-content: center;
  text-transform: uppercase;
}

.btn-form-questions {
  margin: 0;
  font-size: 14px;
  padding: 8px 11px 8px;
}

.adc-btn-form-question {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
  padding: 4px 0px 6px 11px;
}

.list-questions {
  margin: 0;
  padding: 0;
  /* width: 350px; */
  height: 55px;
  display: flex;
  margin-bottom: 15px;
  align-items: flex-end;
}

.body-question {
  width: 350px;
  margin: 10px 0px 10px;
}

.form-btn-body {
  display: flex;
  margin: 0px 10px;
  justify-content: flex-start;
}

.tooltip-remove-text-form {
  width: 100%;
  display: flex;
  position: relative;
}

.body-form-details {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
}
