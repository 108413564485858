.select-form-details {
  margin: 20px 55px 0px;
}

.container-body {
  margin: 0px 55px 0px;
}

.body-view-world {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body-preview-form {
  width: 32%;
}

.body-details-world {
  width: 67%;
  display: flex;
  flex-direction: column;
}

.details-wolrd {
  padding: 16px;
  border-radius: 2px;
  margin: 0px 10px 10px;
  background-color: #ffffff;
  border-bottom: solid 1.3px #e6e6e6;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.title-world {
  font-size: 20px;
  text-align: left;
  color: #403f4c;
  font-weight: 600;
  line-height: 32px;
  font-style: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.first-detais {
  display: flex;
  justify-content: initial;
  padding-left: 10px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #2f313d;
}

.first-detais > .segmentation-container {
  flex-wrap: wrap;
}

.first-detais > .deadline-responses,
.first-detais > .deadline-gamification {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.first-detais > .deadline-responses span,
.first-detais > .deadline-gamification span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
}

.form-progress {
  padding: 0;
  height: 60px;
  display: flex;
  align-items: center;
}

.progress {
  height: 4px;
  width: 460px;
  margin: 0px 10px 0px;
}

.imagicon {
  width: 20px;
}

.progress-description {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  color: #2f313d;
  line-height: 16px;
  margin-right: 10px;
  font-family: Rubik, sans-serif;
  font-style: normal;
}

.tag-segmentation {
  width: 138px;
  height: 24px;
  display: flex;
  margin-right: 5px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  background-color: #b3247c;
}

.tag-text-segmentation {
  margin: 0;
  padding: 0 8px;
  font-size: 12px;
  color: #ffffff;
  line-height: 17px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag-segmentation-sum {
  width: 24px;
  height: 24px;
  display: flex;
  margin-right: 5px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #501f99;
}

.segmentation-container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.user-img {
  width: 32px;
  height: 32px;
}

.img-engagement {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.body-result {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.values-details {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: center;
}

.body-questions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.card-details {
  width: 250px;
  height: 100px;
  padding: 16px;
  margin-right: 16px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.container-question {
  width: calc(33% - 32px);
  display: flex;
  padding: 40px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #d1d4e3;
}

.container-question:not(:nth-of-type(3n + 1)) {
  margin-left: 32px;
}

.body-survey {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}

.body-quiz {
  width: 100%;
}

.header-container-question {
  display: flex;
  flex-direction: row;
}

.body-container-question {
  display: flex;
  min-width: 65%;
  max-width: 100%;
  margin-top: 24px;
  align-items: center;
  flex-direction: column;
}

.title-question {
  margin: 0;
  font-size: 20px;
  color: #2f313d;
  line-height: 21px;
  margin-left: 15px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.img-question-title {
  width: 20px;
  height: 20px;
}

.results {
  font-size: 16px;
}

.results-number {
  font-size: 32px;
}

.color-survey-title {
  width: 16px;
  height: 16px;
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.details-survey {
  margin-bottom: 10px;
}

.percentage-body {
  width: 80%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0px 16px 0px;
  background: #f5f6fa;
}

.percentage-body-survey {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 20px 5px;
  align-items: flex-end;
  background: #f5f6fa;
  padding-top: 10px;
}

.graphic-quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.graphic-survey {
  height: 200px;
  width: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
}

.graphic-survey:nth-child(1) .percentage-body-survey {
  margin: 0;
  border-left: 1px solid #d1d4e3;
}

.text-details-question {
  margin: 0;
  font-size: 16px;
  color: #4b4e5c;
  line-height: 19px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.segmentation-container-survey {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.question-body {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  overflow-y: auto;
  max-height: 400px;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.responses-question {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  color: #4b4e5c;
  line-height: 19px;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  border-bottom: 1px solid #d1d4e3;
}

.imagicon-eye-form-result {
  width: 20px;
  height: 18px;
  cursor: pointer;
}

.title-graphic-quiz {
  text-align: left;
  justify-content: center;
  margin-bottom: 16px;
}

.title-graphic-survey {
  width: 100%;
  height: 50px;
  padding-top: 5px;
  text-align: center;
  justify-content: center;
  border-top: 1px solid #d1d4e3;
}

.body-graphic-survey {
  display: flex;
  justify-content: center;
}

.body-list-subtitle-options {
  padding-left: 16px;
}

.list-subtitle-options {
  margin-bottom: 5px;
}

.download-report-btn {
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  font-size: 15px;
  transition: 0.15s all;
}
.download-report-btn:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
}
.download-report-btn:active {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  filter: brightness(1.15);
}

.download-report-btn-txt {
  display: inline-block;
  width: 150px;
  height: 18px;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: text-top;
  color: rgb(255, 255, 255);
}

.results-form {
  /* background-color: green; */
  margin-left: 20px;
  padding-left: 5px;
  width: 92%;

  border-bottom: 1px solid #e4e4e5;

  display: flex;
  align-items: center;
  justify-content: start;
}

.result-form {
  /* background-color: blue; */
  margin-right: 20px;

  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

  display: flex;
  align-items: center;
}

.low-opacity {
  opacity: 0.75;
}
