.body-modal {
  border-radius: 14px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.modal-header-validation {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  margin-top: 10%;
}

.main-label-import {
  font-family: Rubik;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-import {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-bottom: 2%;
}

.modal-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  height: 15%;
}

.imag-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
}

.description-text-body {
  display: flex;
  justify-content: left;
  flex-flow: column;
  margin-top: 2%;
  padding-right: 2%;
  padding-left: 2%;
}

.text-label-main {
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.secondary-label-list {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down:hover {
  cursor: pointer;
  border-bottom: #999999;
}

.modal-actions-import {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 2%;
  padding-left: 2%;
}
.drop-zone-input {
  border-radius: 7px;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #979797;
  background-color: #ffffff;
}
.label-drop-zone {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 5%;
}

.container-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
}
