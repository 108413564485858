.Select-menu-outer {
  z-index: 100 !important;
}
.error-label-input {
  position: relative;
  bottom: 15px;
  font-size: 12px;
  line-height: 12px;
  color: rgb(244, 67, 54);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.drop-zone-area:hover {
  cursor: pointer;
}

.drop-preview {
  margin: 0;
  padding: 0;
  width: 200px;
  height: 40px;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.drop-preview p {
  padding: 0;
  font-size: 18px;
  color: #3b3b3b;
  margin-top: 15px;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.preview-cover {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.preview-cover img {
  max-width: 100%;
  max-height: 100%;
}

.preview-cover-movie {
  width: 100%;
}

.preview-cover-movie .video-react.video-react-fluid {
  padding-top: 0 !important;
  height: 100px;
  background-color: transparent;
}

.image-cover-container {
  margin: 0;
  padding: 0;
  width: 510px;
  min-height: 170px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.image-cover-default {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.image-cover-default p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #505050;
  margin-left: 10px;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
}

.image-cover img {
  max-width: 510px;
  max-height: 500px;
  cursor: pointer;
  border-radius: 8px;
}

.react-select-container {
  width: 100% !important;
  height: 100% !important;
}

.dropdown-indicator-new {
  width: 15px;
  height: 15px;
  margin-left: -24px;
  z-index: 10;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.clear-indicator-new {
  width: 23px;
  height: 23px;
  font-size: 18px;
  z-index: 15;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  padding: 0 !important;

  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-indicator-new:hover {
  background-color: #ffbdad;
}

.clear-indicator-new > svg > path {
  stroke: red;
}

.dropdown-indicator-new:hover > svg > path {
  stroke: #696969;
}

.info-body-cover {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
