.body-container {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.container-selected-edit {
  margin: 0;
  padding: 0;
  display: flex;
  height: 7.2rem;
  padding-left: 2rem;
  align-items: center;
  justify-items: flex-start;
}

.selected-edit {
  margin: 0;
  padding: 0;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
  font-size: 16px;
  color: #403f4c;
  text-align: left;
  font-family: Rubik;
  font-style: normal;
  margin-right: 20px;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  padding-bottom: 20px;
  letter-spacing: normal;
}

.selected-edit:hover {
  opacity: 1;
  color: #403f4c;
  cursor: pointer;
  border-bottom: 2.1px #e5156b solid;
}

.active-edit {
  opacity: 1;
  color: #403f4c;
  cursor: pointer;
  border-bottom: 2.1px #e5156b solid;
}

.container-course-long {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  border-top: 2px solid #ececec;
}

.body-list-modules {
  width: 25%;
  border-right: 2px solid #ececec;
}

.body-create-modules {
  width: 45%;
  border-right: 2px solid #ececec;
}

.body-preview-subModules {
  width: 30%;
}

.card-module {
  width: 100%;
  height: 75px;
  display: flex;
  padding: 16px;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid #d1d4e3;
}

.card-module:hover {
  background: linear-gradient(
      0deg,
      rgba(179, 36, 124, 0.1),
      rgba(179, 36, 124, 0.1)
    ),
    #ffffff;
}

.card-select {
  background: linear-gradient(
      0deg,
      rgba(179, 36, 124, 0.1),
      rgba(179, 36, 124, 0.1)
    ),
    #ffffff;
}

.new-module-btn {
  margin: 0;
  padding: 0;
  height: 36px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  margin-top: 25px;
  color: #501f99;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  align-items: center;
  font-weight: normal;
  letter-spacing: 0.5px;
  justify-content: center;
  text-transform: uppercase;
}

.new-module-btn p {
  margin: 0;
  padding: 0;
}

.new-module-btn p:nth-child(1) {
  font-size: 30px;
  font-weight: bold;
  margin-right: 10px;
}

.card-title-module {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #2f313d;
  line-height: 21px;
  margin-bottom: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.card-description-module {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #7d86a1;
  line-height: 17px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
}

.container-create-modules {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
}

.title-submodule-field {
  width: 100%;
  position: relative;
}

.title-module-preview {
  font-size: 24px;
  min-height: 30px;
  color: #333947;
  font-weight: bold;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.title-submodule-preview {
  display: flex;
  font-size: 20px;
  min-height: 30px;
  color: #333947;
  font-weight: bold;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
  align-items: center;
  justify-content: space-between;
}

.form-details-module {
  margin: 0;
  padding: 0;
  display: flex;
  padding: 16px;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ececec;
}

.removed-submodule {
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.removed-submodule:hover {
  background-color: #ececec;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.component-content-submodule {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #ccd2e0;
}

.text-title-submodule {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text-title-submodule p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #8c91a9;
  line-height: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
  text-transform: uppercase;
}

.body-content-submodule {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.card-content-submodule {
  width: 100px;
  height: 100px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card-content-submodule p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #333947;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.label-details-SubModule {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.img-submodule {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-submodule svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#img-submodule-svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.desactive-img-submodule svg {
  cursor: no-drop;
}

#desactive-img-submodule-svg {
  cursor: no-drop;
}

.label-details-form {
  margin-top: 24px;
}

.container-content-submodule {
  display: flex;
  margin-top: 24px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.header-content-submodule {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content-submodule-link {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  min-height: 120px;
}

.input-titleLink {
  max-width: 250px;
}

.input-linkContent,
.input-titleLink {
  -webkit-appearance: none;
  color: rgba(0, 0, 0, 0.97);
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;

  box-sizing: border-box;
  font-size: 16px;
  min-width: 250px;
  padding: 5px;
}

.input-linkContent::placeholder,
.input-titleLink::placeholder {
  opacity: 0.5;
}

.input-linkContent:focus,
.input-titleLink:focus {
  outline-style: solid;
  outline-color: #1f89e5;
  outline-width: 0.2px;
  border: none !important;
  margin-bottom: 1px;
  box-shadow: inset 0 0 1.5px 0 black;
}

.description-link {
  min-height: 5px;
  opacity: 0.5;
  font-size: 14px;
  margin-left: 15px;
}

.header-title-img {
  display: flex;
  margin-bottom: 16px;
}

.header-title-img img {
  max-width: 56px;
}

.header-content-description {
  display: flex;
  margin: 8px 16px;
  flex-direction: column;
}

.header-content-description p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #8c91a9;
  line-height: 16px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
}

.header-content-description p:nth-child(2) {
  font-size: 20px;
  color: #2f313d;
  line-height: 28px;
  font-weight: bolder;
}

.container-content-submodule-text {
  width: 100%;
  height: auto;
  padding: 5px;
}

.container-content-submodule-quiz {
  width: 100%;
  height: auto;
}

.container-answers-quiz {
  width: 100%;
  display: flex;
  min-height: 40px;
  padding-left: 16px;
  align-items: center;
}

.text-answers-quiz {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.loading-container {
  position: absolute;
  height: 100%;
  width: 400px;
  margin-top: 0;
  pointer-events: none;
}

.loading-container.loading {
  color: #999999 !important;
}

.loading-container.loading textarea {
  color: #999999 !important;
}

.loading-container.loading::before {
  content: '';
  display: block;
  position: absolute;
  left: 0%;
  height: calc(100% - 5px);
  width: 80px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 0.66s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  transform: translateX(-60px);
  opacity: 0.66;
  margin-left: 30px;
}

@keyframes load {
  from {
    left: 0%;
  }
  to {
    left: calc(100% - 32px);
  }
}

.view-characters {
  margin-top: -4px;
}

.video-cover-container {
  margin: 0;
  padding: 0;
  width: 510px;
  min-height: 170px;
  display: flex;
  position: relative;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.container-title-quiz {
  width: 100%;
  min-height: 40px;
  position: relative;
}

.container-preview-content {
  height: 100%;
}

.header-preview-content {
  padding: 16px;
}

.header-preview-content p {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  font-family: Rubik;
}

.body-preview-content {
  height: 100%;
  padding: 0px 16px;
}

.card-preview-content {
  padding: 24px;
  min-height: 300px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.header-card-preview {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  justify-content: space-between;
  border-bottom: 2px solid #ececec;
}

.header-card-preview p {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  color: #2f313d;
  line-height: 32px;
  font-style: normal;
  text-align: center;
  font-family: Rubik;
}

.arrow-card-submodule {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.arrow-card-submodule:hover {
  background-color: #ececec;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.preview-title-quiz {
  font-size: 20px;
  color: #2f313d;
  line-height: 28px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
  font-weight: bolder;
}

.body-answers-content {
  margin: 24px 0px;
}

.content-answers-preview {
  margin: 0;
  padding: 8px;
  display: flex;
  min-height: 50px;
  margin-top: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
}

.active-content-answers-preview {
  background-color: #73b53b;
}

.content-answers-preview p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  color: #3a3a3a;
  line-height: 28px;
  font-family: Rubik;
  font-style: normal;
}

.active-content-answers-preview p {
  color: #f5f7fa;
}

.body-card-preview {
  margin-top: 24px;
  align-items: center;
  justify-content: center;
}

.preview-description-text p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  color: #7d86a1;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  font-family: Rubik;
}

.preview-description-text p + p {
  margin-top: 1.5rem;
}

.image-preview-card {
  width: 100%;
  height: 50%;
  display: flex;
  border-radius: 8px;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.image-preview-card-video {
  width: 100%;
}

.loading-content {
  width: 100%;
  height: 10px;
}

.ck-content p {
  font-size: 1.5rem;
}

.add__answer__new--container {
  width: 100%;
  height: 36px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add__answer__new--container .add__answer__new {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #501f99;
  background: none;
  border: none;
  cursor: pointer;
}

.add__answer__new--container .add__answer__new:active,
.add__answer__new--container .add__answer__new:focus {
  outline: none;
}

.add__answer__new--container .add__sign {
  display: inline-block;
  margin-right: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #501f99;
}

.remove-answer {
  width: 20px;
  height: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.remove-answer:hover {
  background-color: #ececec;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.154155);
}

.right {
  margin-left: auto;
  padding-right: 16px;
}

.quantity {
  border: 2px solid #b4b4b4;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.send-file2{
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-file2 .drop-zone-icon{
  width: 120px !important;
  height: 123px !important;
  position: relative !important;
  margin-right: 500px;
}

.content-submodule-file2{
  max-height: 300px;
  box-shadow: 0px 0px 8px 0px #00000040;
}

.body-send-file2{
  max-height: 300px;
}

.list-send-file2{
  max-height: 270px;
  margin-top: 0px !important;
  box-shadow: none !important;
  border-left: 1px solid #00000040;
  border-radius: 0 !important;
}

.title-list-file2{
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;

  color: #000000 !important;
  opacity: 0.65 !important;
}

.body-list-file2{
  padding: 0 !important;
  border-top: 1px solid #00000040;
  height: 100%;
  overflow-x: hidden !important;
  justify-content: initial !important;
}

.file-selected2{
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;

  color: #000000 !important;
  opacity: 0.6 !important;

  border-bottom: 1px solid #00000040;
  min-height: 50px !important;
}

.file-name2{
  margin-left: 5px !important;
}
