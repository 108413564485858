.row-devices-porc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.row-device-progress {
  margin-left: 20px;
  margin-right: 20px;
}

.label-devices {
  font-family: Rubik;
  font-size: 17.9px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b3b3b;
}
