.speaker-card-body {
  width: 30%;
  height: 80px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  margin-bottom: 50px;
}

.device-card-body {
  width: 62%;
  height: 100px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  margin-bottom: 50px;
}

.label-name-speaker {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #333333;
}

.main-label-speaker {
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 20px;
}

.speaker-display-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.label-score-dash-user {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  color: #da9c00;
}

.speaker-row {
  display: flex;
  align-items: left;
  flex-flow: column;
}

.speaker-label {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
  margin-top: -10px;
}
