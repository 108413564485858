.div-line {
  margin-top: 2%;
  width: 70%;
  height: 1px;
  border: solid 1px #dedcdc;
  opacity: 0.7;
  margin-bottom: 2%;
}
.body-form-title {
  display: flex;
  width: 80%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.create-event .body-form-title:first-child {
  justify-content: space-between;
}

.body-form-envet-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.body-form-tag {
  width: 50%;
}
.body-form-date {
  width: 30%;
}

.body-calendar-form {
  margin-top: 4%;
  width: 70%;
  opacity: 0.6;
}

.body-calendar-form-valid {
  margin-top: 4%;
  width: 70%;
}
.create-event {
  display: flex;
}

.body-preview-event {
  margin-top: 4%;
  height: 500px;
  width: 30%;
  border-top: 5px solid #e5156b;
  border-radius: 8px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.17);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
.date-today-label {
  font-family: Rubik;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.header-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
.label-no-events {
  margin-top: 56%;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}
.user-association-tags {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 1%;
  color: #999999;
  margin-top: 6%;
}
.body-form-tag-row {
  display: flex;
  align-items: center;
}

.common-label {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: left;
  color: #333333;
  margin-right: 2px;
  margin-left: -4px;
}
.img-click:hover {
  cursor: pointer;
}

.input-holiday {
  display: flex;
  align-items: center;
  min-width: 115px;
}

input[type='time']::-webkit-clear-button,
input[type='time']::-webkit-outer-spin-button,
input[type='time']::-webkit-input-placeholder,
input[type='time']::-webkit-inner-spin-button {
  display: none;
}

.body-comfirm-event {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.label-comfirm-text {
  font-size: 36px;
  margin-top: 30px;
  color: #3b3b3b;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.label-comfirm-button {
  margin-top: 15px;
  margin-bottom: 25px;
}
