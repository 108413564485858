.body-user-list-dash {
  width: 49%;
  height: 366px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  padding-top: 20px;
}

.main-label-user-engage {
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 20px;
}

.header-user-list {
  height: 40px;
}

.label-score-dash {
  font-family: Rubik;
  font-size: 16px;
  width: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  margin-right: 8px;
  margin-top: 6px;
  color: #da9c00;
}

.label-name-user {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 10px;
}

.flex-avatar-name {
  display: flex;
  align-items: center;
}
.flex-avatar-img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}
