:root {
  --default-font: Rubik, sans-serif;
}

.container-draw-details {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-draw-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-draw-details {
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.header-draw-details {
  font-size: 32px;
  color: #2f313d;
  font-style: normal;
  font-family: 'Rubik';
}

.info-details {
  font-size: 18px;
  font-family: 'Rubik';
  color: #4b4e5c;
  font-style: normal;
  font-weight: normal;
  padding-bottom: 8px;
}

.info-details span {
  color: #808080;
}

.img-user-winner {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 100%;
}

.body-user-winners-list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  margin-top: 10px;
  max-height: 150px;
  align-items: center;
  flex-direction: column;
}

.user-winners-list {
  width: 100%;
  height: 50px;
  display: flex;
  padding: 16px;
  font-size: 16px;
  color: #4b4e5c;
  line-height: 19px;
  font-family: Rubik;
  font-style: normal;
  align-items: center;
  font-weight: normal;
}

.user-winners-list:nth-of-type(odd) {
  background: #e4e4e5;
}
