.header-controll {
  margin-left: 84px;
  margin-right: 84px;
  height: 67px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-control-container {
  height: 67px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.body-chart-header {
  height: 67px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.label-value-total-engagemente {
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.label-value-total-engagemente-text {
  height: 36px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-top: 12px;
  margin-left: 20px;
}

.label-value-total-engagemente-media {
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f22424;
}
.label-value-total-engagemente-up {
  height: 36px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1dbf7a;
  margin-top: 20px;
}

.text-interval {
  width: 193px;
  height: 24px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-top: 16px;
  margin-left: 5px;
}
.text-interval-number {
  font-size: 24px;
  margin-top: 16px;
  color: #3fa949;
}
.text-interval-porcentagem {
  font-size: 20px;
  margin-top: 16px;
  color: #3fa949;
}

.Intervalo-limite-Ab {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}
.text-style-1 {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  margin-top: 16px;
  margin-left: 1px;
  color: #403f4c;
}

.card-chart {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.card-list-body {
  height: 218px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-right: 2%;
  width: 70%;
}

.card-tag-body {
  height: 218px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 29%;
}

.card-list-body-drop {
  height: 240px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-right: 1%;
  width: 35%;
}

.card-list-divisori {
  width: 95%;
  height: 2px;
  opacity: 0.36;
  background: #979797;
  margin-left: 10px;
}

.title-header-card-list {
  padding-top: 30px;
  padding-left: 5px;
  padding-bottom: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.title-header-with-dropbox {
  padding-top: 0px;
  padding-left: 5px;
  padding-bottom: -5px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.title-header-card-simple {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 2px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.header-options {
  height: 12px;
  margin-left: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  opacity: 0.52;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}

.header-options:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
}

.img-icon {
  height: 30px;
  padding-left: 10px;
}
.publicatio-title {
  height: 17px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.publication-description {
  width: 320px;
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

.CircularProgressbarItem {
  /*
   * This fixes an issue where the CircularProgressbarItem svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  width: 109px;
}

.CircularProgressbarItem .CircularProgressbarItem-path {
  stroke: #e5156b;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbarItem .CircularProgressbarItem-trail {
  stroke: #d6d6d6;
}

.CircularProgressbarItem .CircularProgressbarItem-text {
  fill: #e5156b;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbarItem .CircularProgressbarItem-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbarItem
 *     className="CircularProgressbarItem-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-background {
  fill: #e5156b;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-text {
  fill: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-path {
  stroke: #fff;
}

.CircularProgressbarItem.CircularProgressbarItem-inverted
  .CircularProgressbarItem-trail {
  stroke: transparent;
}

.CircularProgressbarItemdetermineted {
  fill: black;
}

.best-tag-user {
  margin-top: 10px;

  height: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e5156b;
}
.tag-normal {
  width: 19px;
  height: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
}

.tag-used {
  height: 12px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  margin-bottom: 15px;
}

.chart-header {
  height: 17px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.6;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.likes-legend {
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e5156b;
}

.views-legend {
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
}
.comments-legend {
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1bab60;
}

.circulo-views {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #616161;
  margin-left: 5px;
}

.circulo-comments {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #1bab60;
}
.circulo-likes {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #e5156b;
}

.device-ios {
  width: 76px;
  height: 14px;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e5156b;
}

.device-android {
  width: 76px;
  height: 14px;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
}
.label-score {
  height: 17px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: right;
  color: #da9c00;
}

.number-user-score {
  width: 15px;
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #acacac;
}

.div-dash {
  width: 100%;
  margin-top: 2px;
  height: 1px;
  opacity: 0.45;
  border: solid 1px #979797;
}

.simple-card {
  height: 137px;
  margin-top: 10px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.label-holder {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.43;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.label-avarage {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.43;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.label-drop-down {
  height: 17px;
  font-family: Rubik;
  margin-left: -25px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.place-holder-text {
  height: 17px;
  opacity: 0.63;
  margin-top: 10%;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.load-graphic {
  margin-top: 150px;
  margin-bottom: 100px;
  margin-left: 50%;
}

.teste {
  margin-left: 50px;
  margin-right: 20px;
  width: 400px;
  height: 400px;
}
.ico-down {
  height: 9px;
  width: 12px;
  margin-right: 5px;
}

.img-content {
  margin-left: 84px;
}

.label-avarage-text {
  margin-top: 25px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.43;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.title-body-list-post {
  display: flex;
  align-content: center;
  width: 50%;
  margin-left: 2%;
  margin-top: 5px;
}

.info-body-list-post {
  margin-top: 10px;
  display: flex;
  align-content: flex-end;
  padding-left: 40%;
}

.row-post-and-tags {
  display: flex;
  align-items: center;
}

.body-card-tag {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.col-tags-card {
  display: flex;
  flex-flow: column;
}
.header-section {
  display: flex;
  align-items: center;
  height: 50px;
}
.card-course-body {
  margin-left: 1%;
  width: 35%;
}

.body-users-cards {
  display: flex;
  align-items: center;
}
.container-simple-card-col {
  display: flex;
  flex-flow: column;
  margin-left: 1%;
  width: 29%;
}

.card-product-list {
  height: 218px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 40%;
  margin-right: 1%;
}

.body-cards-products {
  display: flex;
}

.body-card-average {
  display: flex;
  align-content: center;
  justify-content: space-around;
}

.body-card-average-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
