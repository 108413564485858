.header-interaction-row-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
}

.element-header-interaction {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 25%;
}

.main-label-interaction {
  font-family: Rubik;
  font-size: 42.7px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-interaction {
  font-size: 18.4px;
  color: #999999;
  font-family: Rubik;
  font-size: 16.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
}
.porc-label-positive {
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #38d486;
}
.porc-label-negative {
  font-family: Rubik;
  font-size: 19.1px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d23155;
}
.border-right-element {
  width: 1px;
  height: 79px;
  opacity: 0.59;
  background-color: #979797;
}

.ic-porc-interaction {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.row-porc {
  display: flex;
  flex-flow: column;
  align-items: center;
}
