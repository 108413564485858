.place-holder-body {
  display: flex;
  align-items: center;
  width: 100%;
  height: 300px;
  justify-content: space-around;
}

.label-place-holder {
  font-family: Rubik;
  font-size: 36.4px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
