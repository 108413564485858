.label-dialog-main {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a6a6a6;
  width: 70%;
  margin-left: 1%;
}

.label-dialog-main-select {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.label-dialog-main-select:hover {
  cursor: pointer;
  border-bottom: 1px solid #3b3b3b;
}

.label-carousel {
  opacity: 0.45;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.label-dialog-main-select:hover {
  cursor: pointer;
  opacity: 1;
}

.exit-dialog {
  font-size: 36px;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  background-color: #ffffff;
}
.exit-dialog-text {
  font-size: 36px;
  cursor: pointer;
}

.exit-dialog-text:hover {
  cursor: pointer;
  opacity: 0.5;
}

.body-dialog-row {
  height: 7px;
  border-top: 5px solid #ffff;
  border-radius: 5px 3px 3px 10px;
}
.body-dialog-row:hover {
  cursor: pointer;
}

.dropzone-class:hover {
  cursor: pointer;
}
.img-button-click {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border: none;
  margin-right: 3%;
}
.img-button-click:hover {
  cursor: pointer;
  opacity: 0.5;
}

.erro-images {
  text-align: center;
  opacity: 0.45;
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 30px;
}

.dropzone-class {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
}

.dropzone-class-border {
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
}

.body-row-exit {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
.body-row-exit:hover {
  cursor: pointer;
  opacity: 0.3;
}

.form-link-class {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 3%;
}

.form-link-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: -10%;
  margin-right: 3%;
}
