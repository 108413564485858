.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 730px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide > div {
  width: 100%;
  height: 100%;
}

.carousel .slide {
  background: #e6e6e6 !important;
}

.carousel .slide img {
  height: 100%;
  object-fit: contain;
}

.carousel .thumb {
  width: 80px;
  height: 80px;
}

.carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.caroussel__btn {
  position: absolute;
  z-index: 2;
  top: calc(50% - 434px);
  width: 30px;
  height: 730px;
  cursor: pointer;

  border: none;
  background-color: rgba(0, 0, 0, 0.3);
  outline: none;

  color: white;
}

.caroussel__btn:focus {
  outline: none;
}

.caroussel__btn--left {
  left: 15px;
}

.caroussel__btn--right {
  right: 15px;
}

.body-preview .carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 307px;
}

.body-preview .carousel .slider-wrapper.axis-horizontal .slider .slide > div {
  width: 100%;
  height: 100%;
}

.body-preview .carousel .slide {
  background: #e6e6e6;
}

.body-preview .carousel .slide img {
  height: 100%;
  object-fit: contain !important;
}

.body-options-form .carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 690px;
}

.body-question-preview
  .carousel
  .slider-wrapper.axis-horizontal
  .slider
  .slide {
  height: 307px;
}

.body-question-preview
  .carousel
  .slider-wrapper.axis-horizontal
  .slider
  .slide
  > div {
  width: 100%;
  height: 100%;
}

.body-options-form .caroussel__btn {
  height: 690px;
  top: calc(50% - 413px);
}

.body-question-preview .video-react {
  margin-bottom: 16px;
}

.body-preview .img-form-result,
.body-question-preview .img-form-result {
  margin-bottom: 16px;
}
