.tag-publication-body {
  width: 38%;
  height: 450px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  margin-bottom: 50px;
  padding: 15px;
}

.header-tag-dash {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}

.main-label-tag-dash {
  font-family: Rubik;
  font-size: 18.8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.secondary-label-tag-dash {
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
}
