.form-login {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 5%;
  padding: 50px;
  display: flex;
  width: 50%;
  height: 70%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.container-login {
  display: flex;
  justify-content: center;
}

.forget-password {
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-top: 20px;
  margin-bottom: 20px;
}
body {
  background: #f2f2f2;
}
.forget-password:hover {
  color: black;
  cursor: pointer;
}
input:-webkit-autofill {
  background-color: white !important;
}
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.btn-area-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inside-body-login {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: white !important;
}

.password-row {
  display: flex;
  width: 256px;
  align-items: center;
  justify-content: flex-end;
}

.toggle-visibility-icon {
  cursor: pointer;
  position: absolute;
  width: 22px;
  margin-top: 18px;
  margin-right: 6px;
}

.g-recaptcha {
  margin-top: 24px;
}
