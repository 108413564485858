.label-users-length {
  font-family: Rubik;
  font-size: 18.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}
.row-users {
  display: flex;
  padding-top: 7%;
  margin-bottom: 5%;
  flex-direction: row;
  justify-content: space-between;
}
.display-chips {
  display: flex;
  padding-right: 10px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.body-form-score {
  padding: 10px;
}
.form-to-score {
  display: flex;
  flex-flow: column;
  width: 70%;
  margin-top: 5%;
}
.submit-btn {
  position: relative;
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-exit {
  width: 15px;
  height: 15px;
}

.btn-exit:hover {
  cursor: pointer;
}

.btn-remove-chip {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  padding-left: 0;
}

.btn-remove-chip:hover {
  cursor: pointer;
}

.chip-body {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

::scrollbar {
  width: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 20px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(252, 249, 249);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf;
}

.body-comfirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
}

.body-form-score-comfirm {
  height: 100%;
  margin-top: 50%;
}

.label-comfirm-main {
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.label-comfirm-secondary {
  opacity: 0.72;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}

.row-exit-confirm {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.body-form-tag-insert {
  width: 500px;
  height: 400px;
}
