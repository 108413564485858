.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-calendar-action {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #333333;
}

.header-calendar-action:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 0.7;
  color: #403f4c;
}

.calendar-header-title {
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.actions-calendar-title {
  margin-top: 2%;
  display: flex;
  justify-content: left;
  align-items: left;
  width: 40%;
}

.header-actions-row-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2%;
}
