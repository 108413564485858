:root {
  --default-font: Rubik, sans-serif;
}

.header-row {
  border-bottom: solid 1px #d8d8d8;
}

.btn-add-component {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  height: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-add-component:hover {
  cursor: pointer;
}

.btn-add-component-label {
  opacity: 0.85;
  font-family: var(--default-font);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: 15px;
}

.btn-add-component-fixed {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
  position: fixed;
  top: 10px;
  right: 11%;
  height: 46px;
  width: 400px;
}

.hide-select-components {
  display: none;
}
.select-components-show {
  display: initial;
}

.teste_hide {
  display: none;
}
.teste_show {
  display: initial;
}

.header-segmentation-tag-edit {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.header-segmentation-tag-edit h2 {
  margin: 0;
  padding: 0;
  opacity: 0.85;
  font-size: 22px;
  color: #3b3b3b;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: normal;
  padding-bottom: 25px;
  font-stretch: normal;
  font-family: var(--default-font);
  letter-spacing: normal;
}

.header-segmentation-tag-field {
  display: flex;
  align-items: center;
}

.header-segmentation-tag-field > div {
  flex-grow: 1;
}

.tooltip {
  font-family: var(--default-font);
  font-size: 12.8px;
  text-align: center;
  padding: 5px !important;
  height: 24.9px;
  color: #000000;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
}

.container-edit-tags-segmentation {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.special-post-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.special-post__field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.special-post__label {
  cursor: pointer;
  font-family: var(--default-font);
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.55;
  padding-bottom: 2px;
}

.special-post__label:hover {
  padding-bottom: 0px;
  border-bottom: 2px solid;
}

.cancel-edit {
  position: absolute;
  width: 10px;
  height: 10px;
}

.cancel-edit > img {
  position: relative;
  margin-left: 125px;
  margin-top: 39px;
  cursor: pointer;
}

.cancel-date > img {
  margin-top: 46px !important;
  z-index: 2;
}
