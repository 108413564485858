.row-header-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
}
.label-header-calendar {
  height: 32px;
  font-family: Rubik;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.menu-item-cancel {
  font-family: Rubik;

  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.5;
  color: #acacac;
  cursor: pointer;
}

.menu-item-cancel:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 1;
  height: 100%;
  color: #403f4c;
}
.actions-header {
  display: flex;
  align-items: center;
}
