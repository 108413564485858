.select-component-body {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  height: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.label-title-component {
  font-family: Rubik;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 40%;
}

.select-component-fixed-topo {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
  position: fixed;
  top: 10px;
  right: 11%;
  height: 46px;
  width: 400px;
}

.image-title-component {
  margin-top: 10px;
  margin-left: 40%;
}

.body-select-component {
  border-right: solid 1px #d8d8d8;
  height: 46px;
}
.body-select-component:hover {
  cursor: pointer;
  background-color: #ededee;
}

.label-title-component:hover {
  opacity: 0.3;
  cursor: pointer;
}
