/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

.CircularProgressbar {
  /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     *
     * If you're not using "display: flex", you can remove this style.
     */
  width: 50%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #e5156b;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #e5156b;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #e5156b;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.CircularProgressbardetermineted {
  margin-left: 15%;
  fill: black;
}

.alert-dialog-body {
  border-radius: 5px;
  height: 100%;
  border-top: 8px solid #e5156b;
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.label-dialog {
  height: 60px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.submessage-dialog {
  color: rgba(123, 123, 123, 1);
  font-size: 16px;
  width: 80%;
  text-align: center;
}

.btn-cancel {
  width: 110px;
  height: 40px;
  border-radius: 7px;
  border: solid 2px #999999;
  background-color: #ffffff;
  opacity: 0.5;
  margin-right: 23px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
}
.btn-cancel:hover {
  cursor: pointer;
}

.btn-confirm {
  width: 110px;
  height: 40px;
  border-radius: 7px;
  background-image: linear-gradient(to right, #e5156b, #e5156b);
  border: none;
  margin-top: 50px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.btn-confirm:hover {
  cursor: pointer;
}
.dialog-yes-no-body {
  margin-top: 40px;
}

.text-descri-tag {
  margin-top: 16px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
}
.edit-tag {
  color: #403f4c;
}

.body-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.main-dialog {
  /* background: rosybrown; */
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.buttons-dialog {
  /* background: red; */
  width: 70%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-copy-address {
  margin-top: 0 !important;
  width: 180px !important;
}

.user-address {
  margin-left: 3px;
  /* background: green; */
}

.user-address,
.user-name,
.user-phoneNumber {
  /* background: rebeccapurple; */
  display: flex;
  justify-content: flex-start;
  min-width: 250px;
  max-width: 400px;
}

.span-user-name,
.span-user-phoneNumber {
  margin-left: 10px;
}

.span-user-address {
  margin-left: 15px;
  text-align: left !important;
}
