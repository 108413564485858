.header-row-notification-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5px;
  border-radius: 5px;
}

.header-row-notification-header:hover {
  cursor: pointer;
}

.row-actions-dialog-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.row-actions-dialog-btn {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 5%;
}
.label-header-dialog {
  font-family: Rubik;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.column-notification-dialog {
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-top: 20px;
}

.column-notification-text {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 3%;
}

.users-row-select {
  height: 200px;
  overflow-y: scroll;
  margin-top: 30px;
}

.row-user-select {
  height: 90px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #999999;
  margin-right: 2%;
  margin-left: 5%;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 5px;
  display: flex;
  flex-flow: column;
}

.row-user-attributes {
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  height: 100px;
  width: 70%;
}

.name-user {
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-left: 10px;
  width: 200px;
}

.row-btn-submit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 2%;
  margin-top: 5%;
}

.row-placeholder-dialog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2%;
  margin-left: 5%;
}

.margin-top-style {
  height: 8px;
  background-color: #e5156b;
  margin-top: -12px;
  margin-left: -13px;
  margin-right: -13px;
  border-radius: 5px;
}

.form-send-push {
  width: 500px;
}
