.container-body {
  margin: 0px 20px 0px;
}

.title-send-file {
  font-size: 25px;
  margin-top: 20px;
  font-weight: 500;
  color: #403f4c;
  font-style: normal;
  margin-bottom: 30px;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.notify-users {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 10px 20px;
}

.title-notify-users {
  margin: 0;
  padding: 0;
  width: 160px;
  font-size: 14px;
  font-weight: 500;
  color: #403f4c;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.body-send-file {
  display: flex;
  flex-direction: row;
}

.send-file {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.list-send-file {
  width: 35%;
  display: flex;
  height: 500px;
  display: flex;
  margin-top: 20px;
  border-radius: 10px;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.body-list-file {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  max-height: 100%;
  overflow-y: auto;
  align-items: center;
  padding: 15px 0px 15px;
  flex-direction: column;
  justify-content: center;
}

.title-list-file {
  padding: 0;
  margin: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #403f4c;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.header-files {
  width: 100%;
  display: flex;
  font-size: 10px;
  color: #999999;
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  padding-bottom: 10px;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
  justify-content: flex-start;
  border-bottom: 2px solid #dee2e6;
}

.file-selected {
  width: 100%;
  height: 20px;
  display: flex;
  font-size: 10px;
  margin-top: 10px;
  color: #808080;
  font-style: normal;
  line-height: normal;
  flex-direction: row;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.file-name {
  width: 80%;
  margin-left: 20px;
}

.file-action {
  width: 20%;
  margin-right: 25px;
  text-align: center;
  justify-content: center;
}
