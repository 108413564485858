.form-sweepstakes {
  display: flex;
  flex-direction: column;
}

.form-sweepstakes label {
  height: 100px;
}

.date-sweepstakes label {
  width: 210px;
}

.qtd-sweepstakes {
  width: 360px;
}

.rules-sweepstakes {
  width: 205px;
}

.body-definitions {
  margin-top: 100px;
  border-top: solid 1px #d8d8d8;
}

.container-sweepstakes {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: solid 1.3px #e6e6e6;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.header-sweepstakes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-header-sweepstakes {
  color: #403f4c;
  font-size: 32px;
  text-align: left;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.header-sweepstakes p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #acacac;
  text-align: left;
  font-style: italic;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.exit-modal-draw {
  width: 100%;
  display: flex;
  padding: 10px 10px 0 0;
  justify-content: flex-end;
}

.body-draw-confirm {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.react-awesome-modal > div > div {
  z-index: 10 !important;
}

.draw-details-list {
  display: flex;
  padding-left: 0;
}

.button-header-sweepstakes-draw-modal {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
