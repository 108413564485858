.label-tag-discleimer {
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.edit-user-div {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.div-body-tag {
  margin-top: 5px;
  margin-bottom: 28px;
  height: 1px;
  opacity: 0.15;
  background-color: #979797;
}

.description-tag {
  opacity: 0.39;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.user-association {
  opacity: 0.55;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.user-association-2 {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}
.div-vertical-tags {
  width: 1px;
  height: 84.3px;
  opacity: 0.43;
  border: solid 1px #979797;
}

.tag-exp {
  margin-top: 6px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.img-edit:hover {
  cursor: pointer;
}

.label-tag-name {
  width: 170px;
  height: 36px;
  font-family: Rubik;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.img-removed-edit {
  display: flex;
}

.img-removed-edit img {
  margin-right: 10px;
}

.removed-sector-body {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  text-align: center;
  align-items: center;
  padding: 0px 40px 0px;
  justify-content: center;
}

.body-button-sector-removed {
  margin-top: 20px;
}

.body-list-sector-users {
  margin: 0;
  padding: 0;
  width: 80%;
  height: 150px;
  display: flex;
  margin-top: 20px;
  overflow-y: auto;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.body-list-container-sector {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.merge-tags__check-box-label {
  display: flex !important;
}
